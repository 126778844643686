.p-tooltip {
    transition: show 2s;
}

.cards {
    min-width: 800px;
}

.card .card-title {
    /* background-color: rgba(0, 0, 0, 0.57); */
    margin: 0px;
    color: #116AAB;
    font-size: 25px;
    font-weight: 400;
}

.card .card-title.little {
    font-size: 18px;
    font-weight: 400;
}

.card .card-body {
    background-color: #F2F2F2;
    min-height: 163px;
}

.card .card-body.little{
    min-height: 60px;
}

.card-img-overlay {
    padding: 3%;
}

.card-text {
    color: #545454;
}

#main .text-title-small{
    justify-content: center;
    display: flex;
}

.p-button-home{
    border: 0px;
    color:white;
    background-color: transparent;
    font: inherit;
    font-weight: 600;
}

.p-button:focus {
  outline: none;
  box-shadow: none;
}

.p-button-home:enabled:hover{
    background-color: transparent;
}

.tooltip-home{
    left: 5%!important;
    width: 70%;
}

#main .text-card {
    background-color: rgba(0, 0, 0, 0.57);
    margin: 0px;
    }
.card.card-overlay .card-image:after {
    background: rgba(0, 0, 0, 0.00);
    }
.p-tooltip-arrow {
    border-bottom-color: #49505700!important;
    border-right-color: #49505700!important;
}
.p-tooltip .p-tooltip-text {
    background: #9599a1;
}
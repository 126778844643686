.country-profile-page h3 {
    font-family: 'Open Sans',Arial ,Helvetica, Verdana, sans-serif;
    font-size: 1.875em;
    margin-top: 30px;
}

/* .profiles-contries-list {
    list-style-type: none;
    } */

    .arabic-class .profiles-country-box {
        margin-left: 45px;
    }

.table-line{
    border-width: 1px;
}

.header-country-profile {
    width: 100%;
    height: 38px;
    background-color: #f2f2f2;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}

.menu-container {
    margin-left: 16.19%;
    border-left: 1px solid #dee2e6;
    height: 37px;
    color: #555555 !important;
}

.text-header {
    font-size: 14px;
}

.link-header {
    color: #555555 !important;
    text-decoration: none;
}

.country-multi {
    width: 275px;
    height: 31px;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    padding-top: 0px;
    outline: none;
}

tr,td,tfoot,thead,tbody,th{
    border-width: 1px;
    color: black;
    text-align: center;
    border: 1px solid rgb(0, 0, 0);
}

.letter-alphabet {
    background-color:#DAE0E3;
    /* margin-right: 50px; */
    /* padding-top: 0.1px;
    padding-bottom: 0.1px; */
}
.profiles-country-box h3 {
    font-weight: normal;
    font-size: 1.25em;
    border-top: 2px solid #EDEDF0;
    padding-top: 2px;
    margin-bottom: 15px;
}

/* #download{
    background: linear-gradient(to bottom, #0e6da5 0%, #0d5399 100%);
    margin: 15px;
    border: none;
    color: #ffffff;
    font-family: inherit;
    padding: 6px 20px;
    border-radius: 5px;
    font-size: 0.9em;
    font-weight: bold;
    text-transform: uppercase;
} */

.profiles-country-box-regional {
    column-width: 300px;
}
.letter-alphabet h4 { 
    color: #356B76;
    font-weight: 600 !important;
    padding-top: 3px !important;
    padding-bottom: 5px !important;
    line-height: 18px; 
}
.country-link {
    padding: 5px;
    -webkit-column-break-inside: avoid;
}

.country-profiles-heading {
    text-align: left;
}

.section-title-country-page {
    font-weight: normal;
    font-size: 1.25em;
    border-top: 2px solid #EDEDF0;
    padding-top: 2px;
    margin-bottom: 15px;
}

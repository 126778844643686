@import "./variables.scss";

@media screen and (min-width: 200px) and (max-width: 900px) {

    #main {     

        .home {
            border: 1px solid $bg-gray-medium;
            background-image: $bg-image-home;
            background-repeat: no-repeat;
            background-size: 100%;
            box-shadow: inset 3000px 0 0 rgba(0, 0, 0, 0.2);
            height: 200px;
            width: 100%;
            min-width: 200px;

            p {
                // margin-top: 6.5rem;
                // margin-left: 1rem;
                // margin-right: 8rem;
                // padding-bottom: 1rem;
                margin-inline: 1rem;
                color: $bg-white-fao;
                font-size: 8px;
                &:hover {
                    color: $bg-white-fao;
                }
            }

            .color-background {
                background-color: rgba(28,71,103,.8);
                padding: 1rem;
                border-radius: 5px;
                margin-left: 5%;
                margin-right: 5%;
                margin-bottom: 5%;
                position: relative;
            }

            .button-wrap {
                width: 85%;
                // margin-left: 4rem;
                // margin-bottom: 80px;
                margin-top: 10px;

                .button-main {
                    width: 30%;
                    height: 15px;
                    border: none;
                    background-color: $bg-white-fao;
                    color: $bg-primary;
                    border-radius: 2px;
                    font-size: 9px;
                    .text-button {
                        color: $bg-primary;
                        margin-bottom: 20px;
                        
                    }

                    &:hover {
                        background-color: $bg-primary;
                        color: $bg-white-fao;
                        .inside-icon {
                            color: $bg-white-fao;
                        }
                    }

                    &:first-child {
                        margin-bottom: 5px;
                    }

                    .inside-icon {
                        color: $bg-primary;
                        float: left;
                        margin-left: 15%;
                        margin-top: 3px;
                        font-size: 5px;
                    }
                }
            }
        }

        .text-home {
            p {
                color: $bg-gray-dark;
                font-weight: 600;
                font-size: 1.5rem;
            }
        }

        #header-mobile {
            position: fixed;
            left: 0;
            width: 100%;
            z-index: 9999;
            background-color: white;
            .image-fao-mobile {
                width: 105px;
                margin-inline: 5px;
            }
            .header-text-mobile{
                font-size: 11px;
                margin-top: 22px;
            }
            .button-openside-mobile {
                border: none;
                background-color: transparent;
                margin-inline: 10px;
                font-size: 17px;
            }
        }

        .text-title {
            font-size: 15px;
        }

        .text-card {
            font-size: 7px;
            color: $bg-white-fao;
        }

        // .accordion {
        //     // margin-bottom: 20px;
        //     // margin-top: 25px;
        //     // width: 90%;
        //     // font-size: 10px;
        //     //height: 50px;
        //     // .about {
        //     //     font-weight: bold;
        //     //     margin-bottom: 0;
        //     //     font-size: 10px;
        //     // }
        // }

        .accordion-body {
            height: auto;
            overflow: auto;

            p {
                font-size: 10px;
            }

            h5 {
                font-weight: bold;
            }
        }       

        .wrap-links {
            margin-top: 17px;
            h5 {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 20px;
            }

            .related-links {
                margin-block: -5px;
                margin-left: -30px;
                font-size: 12px;
                .inside-button {
                    color: $bg-primary;
                    float: left;
                    margin-right: 10px;
                    width: 12px;
                }

            }

        }

        #results-adv {
           
            .result-space {
                border-bottom: 1px solid $bg-gray-medium;

                .topics {
                    .accordion {
                        flex: 1 0;
                        height: 15%;
                        margin-left: 0;

                        .topic-acc-body {
                            height: auto;
                        }
                    }

                    .topic-wrap {
                        display: flex;
                        margin-block: 1rem;

                        .topic-name {
                            flex: 1 0;
                            width: 10%;

                            h4 {
                                font-size: 16px;
                                font-weight: bold;
                            }
                        }

                        .topic-text {
                            width: 75%;
                            margin-inline-start: 10px;

                            p {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }        

        #country {
            .country-wrap {
                display: flex;
                margin-bottom: 20px;

                .section-flag {
                    margin-right: 12px;

                    img {
                        width: 60px;
                    }
                }

                .section-name {
                    display: flex;
                    margin-top: 5px;

                    h3 {
                        font-size: 25px;
                        color: $bg-primary;
                    }
                }
            }
        }

        #title-date {
            .title-date-wrap {
                display: flex;
                margin-block: 20px;

                .pdf-icon {
                    margin-top: -15px;
                    margin-right: 5px;
                }

                .section-title {
                    width: 70%;
                    h3 {
                        font-size: 20px;
                    }
                }

                .section-date {
                    margin-left: 20px;
                }
            }
        }

    }

    #footer {
        padding: 2.5rem 3.5rem 2.5rem 3.5rem;
        display: flex;
        h6 {
            font-size: 12px;
            color: $bg-gray-medium;
        }

        .logos {
            border-bottom: 3px solid $bg-white-fao;

            img {
                width: 350px;
            }
        }

        .footer-social {
            margin-left: 50px;

            .social {
                margin-top: 5px;
                margin-bottom: 50px;

                img {
                    margin-top: 2px;
                    margin-right: 8px;
                }
            }

            .download-app {
                margin-bottom: 30px;

                img {
                    margin-right: 8px;
                }
            }

            .copyright{
                p {
                    font-size: 12px;
                }          
            }

        }

        .copyright{
            p {
                font-size: 12px;
            }          
        }

        .footer-elements {
            display: flex;
            justify-content: space-between;
            .links-footer {
                .bottom-links-text {
                    color: $bg-primary;
                    opacity: 1;
                    text-decoration: none;
                    font-size: 12px;
                }
            }
        }
    }

}
.header-country-profile {
    width: 100%;
    height: 38px;
    background-color: #f2f2f2;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}

.menu-container {
    margin-left: 16.19%;
    border-left: 1px solid #dee2e6;
    height: 37px;
    color: #555555 !important;
}

.text-header {
    font-size: 14px!important;
}

.link-header {
    color: #555555 !important;
    text-decoration: none;
}

.country-multi-header {
    width: 275px!important;
    height: 31px!important;
    border: 1px solid #d3d3d3!important;
    border-radius: 5px!important;
    padding-top: 0px!important;
    outline: none!important;
}

.country-multi-header .p-dropdown-label.p-placeholder {
    margin-top: -7px!important;
    font-size: 14px!important;
    color: #555555!important;
}


label {
    margin-inline: 10px;
    font-size: 13px;
}

h5 {
    font-size: 16px;
}

.p-dropdown {
    height: 40px;
}

.p-inputtext {
    margin: -5px;
    height: 42px
}

.p-multiselect .p-multiselect-label.p-placeholder {
    margin: -5px;
}

.p-multiselect-label-container {
    overflow: hidden;
    flex: 1 1 auto;
    cursor: pointer;
    height: 40px;
    vertical-align: middle;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 0.375rem 0.75rem;
    margin-right: 0.5rem;
    background: #dee2e6;
    color: #495057;
    border-radius: 16px;
    height: 27px;
}

.topic-multi {
    width: 100%;
}

.sort-drop {
    width: 10%;
    min-width: 150px;
}

.ps-multi {
    width: 100%;
}

.country-multi {
    width: 100%;
}

.territorial-multi {
    width: 100%;
}

.inputs-text {
    width: 100%;
}

.operator {
    margin-top: 31px;
    height: 42px;
}

.operator-title {
    margin-top: 2px;
    height: 42px;
}

.repealed {
    padding:5px;
    color: white;
    background-color: red;
    font-size: 10px;
    border-radius: 5px;
    margin-left: 10px;
    margin-top: 9px;
    font-weight: 600;
}

.repealed-wrap {
    display: flex;
    justify-content: space-between;
    width: 15%;
    min-width: 240px;
}

.repealed-text {
    margin-top: 4px;
}

.repealed-check {
    margin-right: 60px;
}

.check:first-child {
    margin-bottom: 18px;
}

.check label {
    font-weight: bold;
    font-size: 14px;
}

.infoDocs {
    background-color: #f0f0f0;
    padding: 4px;
    font-size: 16px;
    margin-right: 8px;
    min-width: 85px;
}

.wrap {
    margin-top: 36px;
    margin-left: 40px;
}


.chips {
    margin-block: 15px;
}

.p-chips .p-chips-multiple-container {
    background-color: transparent;
    border: none;
}

.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
    border-color: transparent;
}

.button-facets {
    border: none;
    background-color: transparent;
    text-align: left;
    font-size: 12px;
    color:#0d6cac;
}

.button-facets-disabled {
    border: none;
    background-color: transparent;
    text-align: left;
    font-size: 12px;
    color: gray;
}

.button-more-less {
    border: none;
    background-color: transparent;
    font-size: 14px;
}

.result-space {
    padding: 40px;
    margin-block: 8px;
}

@media screen and (max-width: 700px) {
    #main-arabic {
        
        .main-nav {
            width: 100%;
        }

        .main-body {
            margin-top: 50px;
            margin-bottom: 325px;
            .country-profile-page {
                .profiles-country-box {
                    margin-left: 45px;
                }
            }

        }

        .text-nav {
            font: normal normal 600 35px Open Sans;
            margin-top: 16px;
            letter-spacing: 0px;
            color: white;
            opacity: 1;
            margin-left: 20px;
            margin-right: 20px;
        }

        .image-fao {
            width: auto;
            height: 30px;
            margin-top: 15px;
        }

        .header-text {
            font-size: 22px;
            margin-top: 14px;
            margin-left: 5px;
            letter-spacing: 0px;
            color: white;
            font-family: "Open Sans", serif;
            opacity: 1;
        }

        .button-nav {
            width: 450px;
            height: 100px;
            border: 1px solid #D3D3D3;
            border-radius: 5px;
            opacity: 1;
            padding-left: 30px;
            padding-right: 30px;
            position: relative;
            background-color: #1c4767;
        }

        .button-nav:hover {
            /* border: 3px solid white; */
            background-color: #296695;
        }

        .button-selected {
            width: 450px;
            height: 100px;
            opacity: 1;
            border: 3px solid white;
            border-radius: 5px;
            opacity: 1;
            padding-left: 30px;
            padding-right: 30px;
            position: relative;
            background-color: #1c4767;
        }
    }
}

@media screen and (min-width: 700px) and (max-width: 900px) {
    #main-arabic {

        .main-nav {
            width: 100%;
        }

        .main-body {
            margin-right: 320px;
            margin-top: 50px;
            margin-bottom: 325px;

            .country-profile-page {
                .profiles-country-box {
                    margin-left: 45px;
                }
            }
        }

        .nav-container {
            width: 10%;
            height: 96%;
            opacity: 1;
            text-align: center;
            margin-top: 40px;
            margin-left: 25px;
            position: absolute;

            .button-nav {
                width: 300px;
                height: 80px;
                opacity: 1;
                border: 1px solid #D3D3D3;
                border-radius: 5px;
                opacity: 1;
                padding-left: 40px;
                padding-right: 40px;
                position: relative;
                background-color: #1c4767;
                &:hover {
                    border: 3px solid white;
                }
            }

            .button-selected {
                width: 300px;
                height: 80px;
                opacity: 1;
                border: 3px solid white;
                border-radius: 5px;
                opacity: 1;
                padding-left: 40px;
                padding-right: 40px;
                position: relative;
                background-color: #1c4767;
            }
        }

        .image-fao {
            width: auto;
            margin-top: 10px;
            height: 50px;
            margin-right: 20px;
            margin-left: 20px;
        }

        .text-nav {
            font: normal normal 600 20px Open Sans;
            margin-top: 16px;
            letter-spacing: 0px;
            color: white;
            opacity: 1;
            margin-left: 20px;
            margin-right: 20px;
        }

        .header-text {
            font-size: 34px;
            margin-top: 22px;
            margin-left: 10px;
            letter-spacing: 0px;
            color: white;
            font-family: "Open Sans", serif;
            opacity: 1;
        }

        .img-home {
            height: 30px;
            margin-top: 28px;
            margin-left: 10px;
        }
    }
}

@media screen and (min-width: 900px) {
    #main-arabic {
        font-family:'Open Sans',Arial ,Helvetica, Verdana, sans-serif;
        .main-nav {
            width: 100%;
            position: absolute;
        }

        .select-lang {
            margin-right: 20px;
            margin-left: 20px;
            border: none;
            border-radius: 10px;
            padding: 2px;
            font-size: 14px;
            option{
                margin: 2px;
            }
        }

        .home {
            border: 1px solid gainsboro;
            background-image: url(../images/_home/Farmers-in-Taveta-Kenya.jpeg);
            background-repeat: no-repeat;
            background-size: 100%;
            box-shadow: inset 2000px 0 0px rgba(0, 0, 0, 0.2);
            p {
                margin-top: 8rem;
                margin-right: 4rem;
                margin-left: 54rem;
                padding-bottom: 1rem;
                color: whitesmoke;
                &:hover {
                    color: whitesmoke;
                }
            }

            .button-wrap {
                width: 90%;
                margin-right: 4rem;
                margin-bottom: 80px;
                margin-top: 70px;

                .button-main {
                    width: 550px;
                    height: 35px;
                    border: none;
                    background-color: whitesmoke;
                    color: #0d6cac;
                    border-radius: 2px;

                    .text-button {
                        color: #0d6cac;
                        margin-bottom: 20px;
                    }

                    &:hover {
                        background-color: #0d6cac;
                        color: whitesmoke;
                        .inside-icon {
                            color: whitesmoke;
                        }
                    }

                    &:first-child {
                        margin-bottom: 15px;
                    }

                    .inside-icon {
                        color: #0d6cac;
                        float: right;
                        margin-right: 175px;
                        margin-top: 5px;
                        margin-left: -200px;
                    }
                }

                .button-info {
                    color: #0d6cac;
                    background-color: whitesmoke;
                    margin-right: 50px;
                    margin-bottom: 5px;
                    width: 22px;
                    height: 22px;
                    border-radius: 50px;
                    border: none;
                    font-size: 14px;
                    font-weight: 700;
                }
            }
        }

        .accordion {
            margin-right: 5%;
            width: 96%;
            .accordion-button::after{
                margin-right: 88%;
            }
            .about {
                font-weight: bold;
                font-size: 18px;
                margin-bottom: 0;
            }
        }

        .accordion-body {
            height: 345px;
            overflow: auto;
            p {
                font-size: 15px;
            }
            h5 {
                font-weight: bold;
            }
        }

        .wrap-cards {
            margin-top: 2px;
            margin-inline: 40px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            .card {
                width: 30%;
                min-width: 260px;
                max-width: 370px;
                height: 210px;
                padding: 65px;
                border-radius: 8px;
                margin-inline: 10px;
                margin-block: 20px;
                box-shadow: inset 600px 0 0px rgba(0, 0, 0, 0.5);

                &:hover {
                    box-shadow: inset 600px 0 0px rgba(90, 90, 90, 0.5);
                }

                p {
                    font-size: 20px;
                    flex-direction: column;
                    color: whitesmoke;
                }
            }
        }

        .wrap-links {
            margin-right: 140px;
            h5 {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 20px;
            }
            .related-links {
                margin-left: 100px;
                margin-block: -5px;
                margin-right: -30px;
                .inside-button {
                    color:#0d6cac;
                    float: right;
                    margin-left: 10px;
                    width: 14px;
                }
                
            }

        }

        .main-body {
            // margin-right: 340px;
            // margin-top: 50px;
            // margin-bottom: 325px;
            background-color: whitesmoke;
            padding: 20px;
          

            .country-profile-page {
                .profiles-country-box {
                    margin-left: 45px;
                    column-width: 250px;
                    width: 96%;
                    margin-top: 10px;
                    margin-bottom: 25px;
                }
            }
        }

        .buttons {
            color: #0d6cac;
            background-color: white;
            border-radius: 5px;
            border: none;
            margin-inline: 8px;
            border: 1px solid;
            font-weight: bold;
            &:hover {
                color: white;
                background-color: #0d6cac;
            }
        }

        .nav-container {
            width: 10%;
            height: 96%;
            opacity: 1;
            text-align: center;
            margin-block: 15px;
            margin-right: 22px;
            .button-openside {
                border: none;
                background-color: transparent;
                color: #1c4767;
              
            }

            .button-nav {
                width: 300px;
                height: 50px;
                display: flex;
                margin-right: -5px;
                margin-top: 25px;
                padding: 12px;
                border-radius: 5px;
                background-color: white;
                border: none;
                &:hover {
                    background-color: lightgray;
                    color: #0d6cac;
                }
            }

            .button-nav-wrap{
               margin-top: 40px;
            }

            .inside-button {
                color:#0d6cac;
                width: 20px;
                margin-left: 20px;
            }

            .button-selected {
                width: 300px;
                height: 50px;
                display: flex;
                margin-right: -5px;
                margin-top: 25px;
                padding: 13px;
                border-radius: 5px;
                border: none;
                &:hover {
                    color: #0d6cac;
                }
            }
        }

        .nav-container-open {
            width: 10%;
            height: 96%;
            opacity: 1;
            text-align: center;
            margin-block: 15px;
            margin-left: 22px;
            .button-openside {
                border: none;
                background-color: transparent;
                color: #1c4767;
                margin-left: 145px;
            }

            .button-nav {
                width: 45px;
                height: 50px;
                display: flex;
                margin-right: -8px;
                margin-top: 25px;
                padding: 12px;
                border-radius: 5px;
                background-color: white;
                border: none;
                &:hover {
                    background-color: lightgray;
                    color: #0d6cac;
                }
            }

            .button-nav-wrap{
               margin-top: 40px;
            }

            .inside-button {
                color:#0d6cac;
                width: 20px;
                margin-right: 25px;
            }

            .button-selected {
                width: 45px;
                height: 50px;
                display: flex;
                margin-right: -8px;
                margin-top: 25px;
                padding: 13px;
                border-radius: 5px;
                border: none;
                &:hover {
                    color: #0d6cac;
                }
            }
        }

        .image-fao {
            width: 300px;
            height: 90px;
            cursor: pointer;
        }

        .text-nav {
            font-size: 20px;
            color: #1c4767;
            margin-left: 25px;
        }

        .header-text {
            font-size: 20px;
            margin-top: 35px;
            letter-spacing: 0px;
            color: black;
            margin-right: 15px;
        }

        .img-home {
            height: 30px;
            margin-top: 28px;
            margin-left: 10px;
        }
    }

    #footer {
        padding: 2.5rem 3.5rem 2.5rem 3.5rem;
        .footer-elements {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        .links-footer {
            .bottom-links-text {
                color: black;
                opacity: 1;
                text-decoration: none;
            }
        }
    }
}

@media screen and (min-height: 1200px) {
    #main-arabic {
        .main-body {
            background-color: whitesmoke;
            padding-inline: 300px;
        }
    }
}
@import "./variables.scss";

@media screen and (min-width: 1200px) {

    #main {
        overflow-x: hidden;

        .home {
            border: 1px solid $bg-gray-medium;
            background-image: $bg-image-home;
            background-repeat: no-repeat;
            background-size: 100%;
            box-shadow: inset 3000px 0 0 rgba(0, 0, 0, 0.2);
            // padding-top: 5%;
            // padding-bottom: 5%;

            p {
                // margin-top: 5%;
                // margin-left: 5%;
                // margin-right: 25%;
                // padding-bottom: 1rem;
                color: $bg-white-fao;

                &:hover {
                    color: $bg-white-fao;
                }
            }

            .color-background {
                // background-color: rgba(28,71,103,.6);
                background-image: linear-gradient(to right, rgba(0,0,0,0.8), transparent);
                padding-top: 93px;
                padding-left: 150px;
                padding-right: 53%;
                border-radius: 5px;
                margin-left: 0%;
                margin-right: 0%;
                min-height: 520px;
                // margin-bottom: 5%;
                position: relative
            }

            .button-wrap {
                // width: 90%;
                // margin-left: 5%;
                // margin-bottom: 80px;
                margin-top: 65px;

                .button-main {
                    width: 275px;
                    height: 31px;
                    border: none;
                    background-color: $bg-white-fao;
                    color: $bg-gray-dark;
                    border-radius: 2px;
                    text-align: left;
                    margin-right: 30px;
                    font-size: 14px;

                    .text-button {
                        color: $bg-gray-dark;
                        margin-bottom: 20px;
                    }

                    &:hover {
                        background-color: $bg-primary;
                        color: $bg-white-fao;

                        .inside-icon {
                            color: $bg-white-fao;
                        }
                    }

                    &:first-child {
                        margin-bottom: 15px;
                    }

                    .inside-icon {
                        color: $bg-gray-dark;
                        float: left;
                        padding-left: 15px
                        // margin-left: 25%;
                        // margin-right: -20%;
                    }
                }
            }
        }

        .text-home {
            p {
                color: $bg-gray-dark;
                font-size: 1.5rem;
                font-weight: 600;
            }
        }

        .text-title {
            font-size: 25px;
        }

        .text-title-small {
            font-size: 18px;
        }

        .text-card {
            font-size: 14px;
            color: $bg-white-fao;
        }


        .accordions {
            margin-bottom: 20px;
            width: auto;
        }

        .accordion-body {
            height: auto;
            overflow: auto;

            p {
                font-size: 15px;
            }

            h5 {
                font-weight: bold;
            }
        }

        #results-adv {

            .result-space {
                border-bottom: 1px solid $bg-gray-medium;

                .accordion {
                    .accordion-item {
                        h2 {
                            &.accordion-header {
                                button {
                                    height: 38px;

                                    h5 {
                                        &.about {
                                            font-size: 15px;
                                            margin-top: 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                }

                .topics {
                    .topic-wrap {
                        display: flex;
                        margin-block: 1rem;

                        .topic-name {
                            flex: 1 0;
                            width: 10%;

                            h4 {
                                font-size: 16px;
                                font-weight: bold;
                            }
                        }

                        .topic-text {
                            width: 85%;
                            margin-inline-start: 10px;

                            p {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }

        #country {
            .section-geo {
                display: flex;

                h3 {
                    font-size: 25px;
                    color: $bg-primary;
                    margin-left: 12px;
                    margin-top: 1px;
                }

                img {
                    width: 60px;
                }
            }

            .country-wrap {
                display: flex;
                margin-bottom: 20px;



                .section-flag {
                    margin-right: 12px;

                    img {
                        width: 60px;
                    }

                }

                .section-name {
                    display: flex;
                    margin-top: 5px;

                    h3 {
                        font-size: 25px;
                        color: $bg-primary;
                    }
                }
            }
        }

        #title-date {
            .title-date-wrap {
                display: flex;
                margin-block: 20px;

                .pdf-icon {
                    margin-top: -15px;
                    margin-right: 5px;
                }

                .section-title {
                    h3 {
                        font-size: 20px;
                    }
                }

                .section-date {
                    margin-left: 20px;
                }
            }
        }

        @media screen and (min-height: 1200px) {
            .main-body {
                padding-inline: 300px;
            }
        }

        #footer {
            padding: 2.5rem 3.5rem 2.5rem 3.5rem;

            h6 {
                font-size: 12px;
                color: $bg-gray-medium;
            }

            .logos {
                border-bottom: 3px solid $bg-white-fao;

                img {
                    width: 350px;
                }
            }

            .footer-social {
                margin-left: 50px;

                .social {
                    margin-top: 5px;
                    margin-bottom: 50px;

                    img {
                        margin-top: 2px;
                        margin-right: 8px;
                    }
                }

                .download-app {
                    margin-bottom: 30px;

                    img {
                        margin-right: 8px;
                    }
                }
            }

            .footer-elements {
                .links-footer {
                    margin-left: 50px;

                    .bottom-links-text {
                        color: $bg-primary;
                        opacity: 1;
                        text-decoration: none;
                    }
                }
            }

        }
    }

}
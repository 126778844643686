@import url("./css/genderlex_style_300-900.scss");
@import url("./css/genderlex_style_900-1200.scss");
@import url("./css/genderlex_style_min1200.scss");
@import url("./css/genderlex_style_ar.scss");
@import url("primereact/resources/themes/lara-light-indigo/theme.css");
@import url("../src/style_fao/fao-theme.min.css");
@import url("../src/style_fao/faohome.min.css");
@import url("../src/style_fao/intyear.min.css");
@import url("../src/style_fao/stories.min.css");
@import url("../src/style_fao/wfd.min.css");
@import "./css/variables.scss";

#main {
    display: flex;
    flex-wrap: wrap;
    z-index: 101;
    a {
        color: $bg-primary;
        text-decoration: none;

        &:hover {
            color: $bg-primary;
            // font-weight: 600;
            text-decoration: underline;
        }
    }

    .header-nav {
        background-color: $bg-gray-light;
        width: 110%;
        height: 60px;
        position: relative;
        margin-top: -15px;
        margin-left: -50px;
        border-bottom: 2px solid $bg-line-sidenav;
    }

    .main-nav-close {
        width: 70px;
        height: 100%;
        position: fixed;
        z-index: 1001;
        left: 0;
        background-color: $bg-gray-light;

    }

    .main-nav-open {
        width: 280px;
        height: 100%;
        position: fixed;
        z-index: 1001;
        left: 0;
        background-color: $bg-gray-light;
    }

    #header {
        display: flex;
        position: fixed;
        left: 0;
        width: 100%;
        z-index: 9999;
        background-color: white;
        .return-home {
            margin-top: 25px;
            margin-right: 55px;
            margin-left: 15px;
            a {
                
                img {
                    width: 50px;
                }
            }
        }
    }

    .main-body {
        padding: 0px;
        // padding: 20px;
        margin-left: 0;
        margin-top: 80px;
        flex: 1 0;
    }

    .main-body-close {
        // padding: 20px;
        // margin-left: 100px;
        margin-top: 85px;
        flex: 1 0;
    }

    .main-body-open {
        padding: 20px;
        margin-top: 85px;
        // margin-left: 290px;
        flex: 1 0;
    }

    .loading {
        position: fixed;
        z-index: 10001;
        background: rgba(48, 50, 48, 0.404);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .wrap-spinner {
            width: 100%;
            text-align: center;
            position: absolute;
            z-index: 10005;
            top: 43.5%;
        }
    }

    .select-lang {
        margin-right: 20px;
        margin-left: 20px;
        border: none;
        border-radius: 3px;
        padding: 2px;
        font-size: 14px;
        background-color: $bg-gray-light;
        margin-top: 10px;
        option {
            margin: 2px;
        }
    }

    .contact {
        background-color: $bg-primary-light;
        border-radius: 5px;
        padding: 10%;
        width: 100%;
        margin-top: 20px;

        p {
            font-weight: 500;
            font-size: 20px;
        }

        a {
            color: $bg-primary;
        }
    }

    .country-profile-box {
        margin-left: 45px;
    }

    .nav-container-open {
        // width: 250px;
        margin-block: 10px;
        margin-left: 22px;

        .button-openside {
            border: none;
            background-color: transparent;
            font-size: 20px;
            margin-left: 215px;
        }

        .button-nav {
            width: 240px;
            height: 30px;
            border-radius: 5px;
            margin-bottom: 5px;
            border: none;
            background-color: transparent;
            display: flex;
            span {
                min-width: 120px;
                &.text-nav {
                    font-size: 14px;
                    text-align: left;
                    margin-top: 3px;
                }
            }

            &:hover {
                background-color: $bg-primary-light;
            }
        }

        .divider-button {
            border-top: 2px solid $bg-line-sidenav;
            margin-top: 10px;
            margin-left: -22px;
            width: 280px;
        }

        .button-nav-wrap {
            margin-top: 20px;
           
        }

        .button-selected {
            width: 240px;
            height: 30px;
            border-radius: 5px;
            margin-bottom: 5px;
            border: none;
            background-color: $bg-primary-light;
            display: flex;
            span {
                min-width: 120px;
                &.text-nav {
                    font-size: 14px;
                    text-align: left;
                    margin-top: 3px;
                    &:first-child {
                        width: 113px;
                    }
                }
            }

        }

    }

    .nav-container-close {
        width: 10%;
        height: 96%;
        opacity: 1;
        text-align: center;
        margin-block: 10px;
        margin-left: 22px;

        .button-openside {
            border: none;
            background-color: transparent;
            margin-left: -4px;
            font-size: 17px;
        }

        .button-nav {
            width: 30px;
            height: 30px;
            border-radius: 5px;
            border: none;
            margin-bottom: 5px;
            background-color: transparent;
            &:hover {
                background-color: $bg-primary-light;
            }
        }

        .button-nav-wrap {
            margin-top: 20px;
        }

        .divider-button {
            border-top: 2px solid $bg-line-sidenav;
            margin-top: 10px;
            margin-left: -22px;
            width: 70px;
        }

        .button-selected {
            width: 30px;
            height: 30px;
            margin-bottom: 5px;
            border-radius: 5px;
            border: none;
            background-color: $bg-primary-light;
        }
    }

    .home-content {
        margin-left: 150px;
        margin-right: 150px;
    }

    .blue-box {
        background-color: $bg-primary-light;
        border-radius: 5px;
        padding: 20px;
        width: 100%;
    }

    .blue-light-box {
        background-color: $bg-white-fao;
        border-radius: 5px;
        padding: 25px 25px 9px 25px;
        width: 100%;
        min-height: 275px;
    }

    .blue-contact-box {
        background-color: $bg-primary-light-2;
        border-radius: 5px;
        padding: 16px;
        width: 100%;
    }

    .blue-title-box {
        font-size: 16px;
        color: white;
        background-color: $bg-primary;
        padding: 10px 12px 10px 12px;
        border-radius: 5px;

        
    }

    .title-box {
        font-size: 20px;
        color: $text-home;
    }

    .text-blue-box {
        font-size: 16px;
        color: $bg-primary;    
    }

    .white-text {
        color: white;

        &:hover{
            text-decoration: underline;
        }
    }

    .text-card-box {
        font-size: 16px;
        color: $text-home;
    }

    .text-box {
        font-size: 16px;
        color: $text-home;
        padding-right: 20px;
    }

    .text-color {
        color: $bg-primary;
    }

    .text-bold {
        font-weight: 600;
    }

    .text-last {
        font-size: 18px;
        color: $text-home;
    }

    .contact-title {
        font-size: 20px;
        color: $bg-primary;
    }

    .contact-text {
        font-size: 16px;
        color: $bg-gray-dark;
    }

    .image-home {
        width: 420px;
        height: 280px;
        // background-color: #999999;
        border-radius: 5px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }
    }

    .image-fao {
        width: 180px;
        cursor: pointer;
        margin-inline: 20px;
    }

    .image-cites {
        width: 66px;
        cursor: pointer;
        margin-inline: 10px;
    }

    .header-text {
        font-size: 20px;
        margin-top: 28px;
        font-weight: 600;
        margin-left: 15px;
    }

    .profiles-country-box {
        column-width: 250px;
        width: 96%;
        margin-left: 35px;
        margin-top: 10px;
        margin-bottom: 25px;
    }

    .title {
        font-size: 20px;
        margin-left: 20px;
    }

    #adv-fields {
        .topics-wrap {
            display: block;

            .main-topics {
                width: 60%;
                margin-left: 120px;
                min-width: 1000px;
                .main-check {

                    min-width: 280px;
                    max-width: 350px;

                    &:first-child {
                        margin-bottom: 18px;
                    }

                    label {
                        font-weight: bold;
                        font-size: 14px;
                    }
                }
            }

            .wrap-other-topics {
                margin-top: 5px;

                .other-topics {
                    width: 60%;
                    min-width: 1000px;
                    label {
                        font-size: 13px;
                        margin-top: 1px;
                    }
                }
            }

        }

        .primary-subject-wrap {
            display: flex;
            flex-wrap: wrap;
            min-width: 1180px;
            .general-checkbox {
                margin-top: 61px;
            }

            .checkbox {
                flex: 0 0;
                width: 100%;
                min-width: 230px;
            }

        }

    }

    #all-documents {
        padding-inline: 60px;

        .header-wrap {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .country {
                display: flex;

                .img-country {
                    margin-left: 8px;
                }

                .name-country {
                    margin-left: 8px;
                }
            }

            .results-found {
                margin-inline-start: 100px;
                margin-top: 10px;
            }

            .buttons-download {
                margin: 1px;
            }
        }
    }

    #documents-results-detail {
        .topics {

            .topic-wrap {
                display: flex;
                margin-block: 1rem;

                .topic-name {
                    flex: 1 0;
                    width: 10%;

                    h4 {
                        font-size: 16px;
                        font-weight: bold;
                    }
                }

                .topic-text {
                    width: 85%;
                    margin-inline-start: 10px;

                    p {
                        font-size: 14px;
                    }
                }
            }
        }

        .title-wrap {
            margin-bottom: 2rem;
            display: flex;

            .title {
                margin-block-start: 10px;
            }

            .icon {
                margin-left: 5px;
                margin-top: -3px;
                margin-right: 10px;
            }
        }

        .section-wrap {
            display: flex;
            flex: 1 0;
        }

        .section-label {
            padding: 15px;
            background-color: $bg-gray-light;
            margin-block: 2px;
            width: 200px;
            height: 45px;
            border-radius: 4px;
            font-size: 12px;
        }

        .section-text {
            padding: 15px;
        }

        .section-text-keyword {
            margin-left: 15px;
            padding: 2px;
            width: 70%;

            b {
                font-weight: 400;
            }
        }

        .section-text-sub {
            margin-left: 4px;
            padding: 15px;

            b {
                font-weight: 400;
            }
        }

        .text {
            margin-block: 20px;
        }

    }

}

.button-nav-header {
    flex-direction: row-reverse !important;
    height: 100%;
    justify-content: start !important;
}

.button-nav-open-panel {
    flex-direction: row-reverse !important;
    height: 100%;
    justify-content: start !important;
    margin-left: -73px;
    width: 48px;
}

.button-nav-open-panel-table {
    flex-direction: row-reverse !important;
    height: 100%;
    justify-content: start !important;
    /* margin-left: -10px; */
    width: 48px;
}

.letter-alphabet {
    background-color: $bg-gray-light;
    margin-top: 10px;
    margin-bottom: 1px;
}
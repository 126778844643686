/* .links {
    color: blue;
    text-decoration: none;
}

.links:hover {
    text-decoration: underline;
    color: blue;
} */

#document-cp {
    border-bottom: 1px solid #dee2e6;
}

.header-country-profile {
    width: 100%;
    height: 38px;
    background-color: #f2f2f2;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}

.menu-container {
    margin-left: 16.19%;
    border-left: 1px solid #dee2e6;
    height: 37px;
    color: #555555 !important;
}

.text-header {
    font-size: 14px;
}

.link-header {
    color: #555555 !important;
    text-decoration: none;
}

.country-multi {
    width: 275px;
    height: 31px;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    padding-top: 0px;
    outline: none;
}

.button-main {
    width: 275px;
    height: 31px;
    border: 1px solid #d3d3d3;
    color: #545454;
    border-radius: 5px;
    text-align: left;
    font-size: 14px;
    background-color: white;

    .text-button {
        color: #545454;
    }

    &:hover {
        background-color: #116aab;
        color: #f7f8f9;

        .inside-icon {
            color: #f7f8f9;
        }
    }

    .inside-icon {
        color: #545454;
        float: left;
        padding-left: 15px;
    }
}

.country-multi .p-dropdown-label.p-placeholder {
    margin-top: -7px;
    font-size: 14px;
    color: #555555;
}

.top_bar {
    min-height: 50px;
    max-height: 50px;
    background-color: white;
    margin: unset;
}

.party-cites {
    font-size: 16px;
}

.nav-mobile {
    /* padding: unset; */
    /* display: none; */
    /* height: 98vh; */
    background: #f2f2f2;
    overflow: auto;
    padding: 20px 20px 0px 20px;
    /* height: 330px; */
    min-height: 100%;
    overflow: hidden;
}

.nav-pop-up {
    position: absolute;
    right: 0px;
}

.pop-up {
    background: #FFFFFF;
    padding: 16px 20px 16px 20px;
    height: 280px;
    overflow: hidden;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    width: 255px;
    margin-left: 60px;
}

.pop-up .title-website {
    font-size: 20px;
    margin: 0;
    padding: 0;
    margin-bottom: 14px;
}

.nav-mobile .title-website {
    font-size: 14px;
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
}

.nav-mobile .title-doc {
    font-size: 14px;
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
    cursor: pointer;
}

.title-doc:hover {
    background: #dbe4ea;
    color: black;
    font-weight: 600;
}

.highlighted {
    background: #dbe4ea;
    color: black;
    font-weight: 600;
}

.nav-mobile .subsection {
    font-size: 14px;
    margin: 0;
    padding: 0;
    margin-top: 10px;
    margin-left: 30px;
}

.description {
    margin: 0;
    padding: 25px 60px 20px 60px;
}

.description .title-desc {
    margin-bottom: 30px;
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    border-bottom: 1px solid #545454;
}

.description .subsection {
    margin-top: 5px;
    font-weight: 600;
}

.see-more {
    font-size: 16px;
    font-style: italic;
    display: flex;
    justify-content: flex-end;
    margin-top: 60px;
    margin-right: 50px;
}

/* .header-profile {
    display: flex;
    justify-content: space-between;
} */

.input-viewdoc {
    margin-right: 4vh;
    float: right;
}

/* .wrap-img-title {
    margin-top: 5px;
} */

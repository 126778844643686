$bg-primary: #116AAB;
$bg-primary-light: #E5ECF4;
$bg-primary-light-2: #E5ECF5;
$bg-gray-dark: #545454;
$bg-gray-medium: #999999;
$bg-gray-light: #F2F2F2;
$bg-white-fao: #F7F8F9;
$bg-orange: #F58320;
$bg-caption: #1C4767;
$bg-emergency: #980000;
$bg-un-blue: #5792c9;
$text-home: #555555;

// IMAGE BACKGROUND HOME
$bg-image-home: url("https://faolex.fao.org/docs/json/citeslex1.jpg");

//custom
$bg-line-sidenav: #dee2e6;
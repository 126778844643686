.spinner {
    margin-left: 500px;
    justify-content: center;
    background-color: aqua;
    
}

.iconPDF {
   display: flex;
   width: 500px;
}

b {
    font-weight: 200;
}